import React from 'react'
import Slider from 'react-slick/lib'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from '@components/images'
import { View } from '../View/View'

// This global css must be imported in `pages/_app.js` since Next.js 11
// import './slick-theme.css'
// import './slick.css'

const FETCH_DELAY = 3000

class SlickSlider extends React.Component {
  constructor(props) {
    super(props)
    this.slider = null
    this.state = {
      autoplay: true,
    }
  }

  componentDidMount() {
    this.mounted = true
    if (this.slider) {
      this.startAutoPlay()
    } else {
      this.delayStart = true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.slider && this.delayStart) {
      this.delayStart = false
      this.timeOut = setTimeout(this.firstTransition, this.props.interval - FETCH_DELAY)
    }

    if (prevProps.interval !== this.props.interval) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(this.firstTransition, this.props.interval - FETCH_DELAY)
    }
  }

  componentWillUnmount() {
    this.mounted = false
    clearTimeout(this.timeOut)
    clearInterval(this.autoPlay)
  }

  firstTransition = () => {
    this.nextItem()
    this.startAutoPlay()
  }

  startAutoPlay = () => {
    if (!this.slider) return

    clearInterval(this.autoPlay)

    this.slider.slickPlay()
    this.setState({ autoplay: true })

    this.autoPlay = setInterval(() => {
      if (this.slider) {
        this.slider.slickNext()
      }
    }, this.props.interval)
  }

  stopAutoPlay = () => {
    if (!this.slider) return
    clearInterval(this.autoPlay)
    this.slider.slickPause()
    this.setState({ autoplay: false })
  }

  nextItem = () => {
    if (this.mounted && this.getItemsArray().length) {
      this.slider.slickNext()
    }
  }

  getItemsArray() {
    return React.Children.toArray(this.props.children)
  }

  render() {
    const { children, className, ...sliderProps } = this.props
    return Slider ? (
      <div>
        <Slider
          {...sliderProps}
          className={className}
          ref={(el) => {
            if (el && !this.slider) {
              this.slider = el
              this.startAutoPlay()
            }
          }}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
        >
          {typeof children === 'function'
            ? children({ onSlickStart: this.startAutoPlay, onSlickStop: this.stopAutoPlay })
            : children}
        </Slider>
      </div>
    ) : (
      <div className={className}>{this.getItemsArray().shift() || null}</div>
    )
  }
}

SlickSlider.propTypes = {
  interval: PropTypes.number.isRequired,
  children: PropTypes.array,
}

const ArrowContainer = styled(View)`
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
`

const NextArrowContainer = styled(ArrowContainer)`
  right: -80px;
`

const PrevArrowContainer = styled(ArrowContainer)`
  left: -80px;
`

const NextArrow = ({ onClick }) => {
  const theme = useTheme()
  return (
    <NextArrowContainer onClick={onClick}>
      <Icon name="chevron-right" size={40} color={theme.colors.gray} />
    </NextArrowContainer>
  )
}
const PrevArrow = ({ onClick }) => {
  const theme = useTheme()
  return (
    <PrevArrowContainer onClick={onClick}>
      <Icon name="chevron-left" size={40} color={theme.colors.gray} />
    </PrevArrowContainer>
  )
}

export default SlickSlider
